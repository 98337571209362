<style lang="less" scoped>
.detailBox {
    min-height: 500px;
    margin:0 auto 100px;
    background: url('~@/assets/img/boxBg.png');
    background-size:100% 100% ;
    background-position: left 0;
    border-radius: 10px;
    box-shadow: 0 0 10px #000000;
    padding-top: 10px;
}
.pipBg {
    background: url('~@/assets/img/boxBg2.png') !important;
    background-size:100% 100% !important;
    background-position: left 0 !important;
}
.detailTitle {
    margin:0;
    padding: 20px;
    text-align: center;
}
.imgWrap {
    width: 20%;
    padding: 0 10px;
    >div {
        margin: 10px 0;
        >div {
            height: 150px;
            // background: magenta;
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            margin-top: 5px;
        }
    }
    
}
.detainMain {
    width: 63%;
    padding: 0 30px;
}
.width1000 {
    max-width: 1000px;
    width:70%;
    margin: auto;
}
.topicWrap {
    width: 17%;
    padding: 20px 0;
    >div {
        padding:10px;
        cursor: pointer;
        user-select: none;
        transition: 0.3s;
    }
    >div:hover {
        background: #D0E5EC !important;
    }
}
.rightI {
    width: 30px;
    height: 20px;
    >img {
        width: 100%;
        height: 100%;
    }
}
.topic {
    width: 60%;
    line-height: 30px;
}
.topicAct {
    background: #D0E5EC;
    color: #333 !important;
}
.zdItem {
    h4 {
        background: #101F5C;
        border: solid #475486 1px;
        margin: 0;
        padding: 6px 13px;
    }
}
.zdCOntent {
    color: #000;
    height: 0;
    overflow: hidden;
}
.showItem {
    height:auto !important; 
}
.width100And10 {
    box-sizing: border-box;
    width: calc(100% + 10px);
    // border: solid red 1px;
    >div {
        float: left;
        width: calc(50% - 10px);
        margin-right: 10px;
    }
}
.listImg {
    width: 100%;
    height: 33vw;
    // border: solid red 1px;
    margin-top: 5px;
    overflow: hidden;
    >img {
        width: 100% ;
        height: 100%;
    }
}
.shadow1 {
    box-shadow: 0 0 10px #000;
    border-radius: 10px;
    width: calc(100% + 20px);
    transform: translateX(-10px);
    padding: 0 15px 15px;
    box-sizing: border-box;
}
.pdfBtn {
    background: #FFF;
    padding: 10px 15px;
    border: solid #102269 2px;
    cursor: pointer;
    user-select: none;
    transition: 0.3s;
    color: #333;
    &:hover {
        box-shadow: 0 0 5px #102269;
    }
    margin: 3px;
}
</style>

<template>
    <div style="padding-top: 100px;" class="p0">
        <div :class="['detailBox width1000 mNone', { pipBg: item.type == 'REHABILITATION' }]">
            <h1 class="detailTitle whiteColor">{{item.title}}</h1>
            
            <div>
                <br><br>
                <div class="flex" style="padding: 0 30px" v-if="item.content.length">
                    <div class="imgWrap">
                        <div v-for="(img,k) in item.content[i].image" :key="k">
                            <span>{{img.imgName}}</span>
                            <div :style="{background:`url(${img.url})`}" @click="preview(k)"></div>
                            <!-- <img :src="img.url" style="width: 100%"  @click="preview(k)" alt=""> -->
                        </div>
                    </div>

                    <div class="detainMain">
                        <div v-html="item.content[i].content"></div>
                        <br><br>
                        <div class="al" v-for="(pdf,i) in item.content[i].pdf" :key="i">
                            <a class="pdfBtn" target="_blank" :href="pdf.link">Case Study{{i+1}}</a>
                        </div>
                        <br><br>
                    </div>
                    <div class="topicWrap whiteColor">
                        <div v-for="(item,j) in item.content" :key="j" :class="['al sb', {topicAct: j==i}]" @click="i=j">
                            <div class="topic whiteColor2" v-html="item.topic"></div>
                            <div class="rightI">
                                <img src="@/assets/img/icon2.png" v-if="i==j" alt="" >
                                <img src="@/assets/img/icon1.png" v-else alt="" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>

        <!-- 移动端 -->
        <div class="mShow shadow1">
            <h1 class="detailTitle whiteColor lh">{{item.title}}</h1>
            <br>
            <div class="zdWrap ">
                <div class="zdItem" v-for="(c,l) in item.content" :key="l">
                    <h4 class="whiteColor" @click="showHide(l)">{{c.topic}}</h4>
                    <div :class="['zdCOntent', { showItem: c.show }]" >
                        <div style="padding: 10px">
                            <div>
                                <div class="clearBoth width100And10">
                                    <div v-for="(img,m) in c.image" :key="m">
                                        <div>Img title</div>
                                        <div class="listImg ju al">
                                            <img :src="img.url" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div v-html="c.content"></div>
                            <br>
                            <div class="al" v-for="(item,i) in c.pdf" :key="i">
                                <a class="pdfBtn" target="_blank" :href="item.link">Case Study{{i+1}}</a>
                            </div>
                        </div>
                        


                    </div>
                </div>
            </div>
        </div>


        <!-- 底部返回 -->
        <div class="width1000">
            <br>
            <div class="sb">
               <div class="blueBtn" @click="back">
                    &lt; Back
                </div> 
            </div>
            
        </div>
        <div class="width1000">
            <br>
            <br>
            <br><br>
            <br><br>
            <br>
            <myFooter></myFooter>
        </div>


        <transition name="fade">
            <div v-if="showImgs">
                <div class="loopMask"></div>
                <div class="loopBox z10 ">
                    <el-carousel ref="loop" :autoplay="false" loop height="500px" class="relative z10" :initial-index="currentImg" @change="loopChange">
                        <el-carouselItem v-for="(item,i) in item.content[i].image" :key="i">
                            <div class="roopImg" :style="{background: `url('${item.url}') no-repeat`}"></div>
                        </el-carouselItem>
                    </el-carousel>
                    <div class="al miar20 sb">
                        <div class="al">
                            <div class="twoBtn al">
                                <div @click="preNext('prev')"></div>
                                <div @click="preNext('next')"></div>
                            </div>
                            <div style="margin-left: 20px">{{currentImg + 1}} / {{item.content[i].image.length}}</div>
                        </div>
                        <div class="close ju al" @click="showImgs=false">
                            <img src="@/assets/img/close.png" alt="">
                        </div>
                    </div>

                    <!-- <div class="loopNav">
                        <div style="width: 100%;height: 100%">
                            <myNav :list="item.big_image" :active="currentImg"></myNav>
                        </div>
                        
                    </div> -->
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data () {
        return {
            uuid: "",
            item: {
                content: []
            },
            i: 0,    // 当前是第几个topic
            showImgs: false,
            currentImg: 0,
        }
    },
    created () {
        this.uuid = this.$route.query.uuid
        this.getData()
    },
    methods:{
        preNext (method) {
            this.$refs.loop[method]()
        },
        loopChange (e) {
            this.currentImg = e
        },
        //预览图片
		preview (i) {
            this.currentImg = i
            this.showImgs = true
			// this.$imagePreview({
			// 	images: [src],
			// 	index: 1,
			// })
		},
        showHide (i) {
            console.log(this.item.content)
            this.item.content.forEach((item,j) => {
                if (i!= j) {
                    item.show = false
                    console.log(i,j)
                } 
                else {
                    item.show = !item.show
                }
            })
            // this.item.content[i].show = true
            this.item = JSON.parse(JSON.stringify(this.item))
        },
        back () {
            this.$router.back()
        },
        getData () {
            window.http({
                url: "content",
                params: {
                    uuid: this.uuid,
                }
            }).then(res => {
                console.log(res)
                if (res.data.code == 200) {
                    res.data.data.content = JSON.parse(res.data.data.content)
                    this.item = res.data.data
                    this.item.content.forEach((item,i) => {
                        item.show = false
                        if (i== 0) {
                            item.show = true
                        }
                        if (item.pdf instanceof Array) {
                            item.pdf = item.pdf.filter(pdf => pdf.link)
                        } else {
                            item.pdf = []
                        }
                        
                        item.topic = item.topic.replace(/[\n]+/g,"<br />")
                    })
                    console.log(this.item)
                } else {
                    this.item = {
                        content: {
                            topic: "",
                            content: "",
                            image: []
                        }
                    }
                }
            })
        }
    }
}
</script>

